window.$ = window.jQuery = require('jquery')

require('./vendor/bootstrap.min')
// require('./vendor/jquery.cbpQTRotator.min.js')
require('./vendor/jquery.stellar.min')
require('./vendor/jquery.easing.1.3')
// require('./vendor/jquery.jigowatt')
// require('./vendor/jquery.nicescroll.min')
require('./vendor/waypoints.min')

$(document).ready(function() {

    $(window).stellar()

    $('.carousel').carousel({
        interval: 4500
    })

    //$('html').niceScroll({cursorcolor: '#FFF', cursorwidth: '6px', cursoropacitymax: '0.5'})

    $('.scroll-slide').waypoint(function(event, direction) {
        //cache the variable of the data-move attribute associated with each slide
        let dataslide = $(this).attr('data-move')
        if (direction === 'down') {
            $('.nav li[data-move="' + dataslide + '"]').addClass('active').prev().removeClass('active')
        } else {
            $('.nav li[data-move="' + dataslide + '"]').addClass('active').next().removeClass('active')
        }
    })

    //waypoints doesnt detect the first slide when user scrolls back up to the top so we add this little bit of code, that removes the class
    //from navigation link slide 2 and adds it to navigation link slide 1.
    $(window).scroll(function() {
        if ($(window).scrollTop() === 0) {
            $('.nav li[data-move="1"]').addClass('active')
            $('.nav li[data-move="2"]').removeClass('active')
        }
    })

    //create a function that will be passed a slide number and then will scroll to that slide using jquerys animate. The Jquery
    //easing plugin is also used, so we passed in the easing method of 'easeInOutQuint' which is available throught the plugin.
    let goToByScroll = (dataslide) => {
        $('html,body').animate({
            scrollTop: $('.scroll-slide[data-move="' + dataslide + '"]').offset().top - 120
        }, 1500, 'easeInOutQuint')
    }

    //when the user clicks on the navigation links, get the data-move attribute value of the link and pass that variable to the goToByScroll function
    $('.nav').find('li').click(function(e) {
        e.preventDefault()
        dataslide = $(this).attr('data-move')
        goToByScroll(dataslide)
    })

    //when the user clicks on the button, get the get the data-move attribute value of the button and pass that variable to the goToByScroll function
    $('.button').click(function(e) {
        e.preventDefault()
        dataslide = $(this).attr('data-move')
        goToByScroll(dataslide)
    })

    $('button[name=sendmail]').click(function(e) {
        e.preventDefault()

        let name = $('input[name=name]').val()
        let email = $('input[name=email]').val()
        let phone = $('input[name=phone]').val()
        let message = $('textarea[name=message]').val()

        let valid = true

        if (!name || typeof name == 'undefined') {
            //$('#block-answer').html('<div class="alert alert-danger"></div>').fadeIn()
            showAlert('name is required.', 'danger')
            valid = false
        }
        if (!phone || typeof phone == 'undefined') {
            //$('#block-answer').html('<div class="alert alert-danger">phone is required.</div>').fadeIn()
            showAlert('phone is required.', 'danger')
            valid = false
        }
        if (!email || typeof email == 'undefined') {
            //$('#block-answer').html('<div class="alert alert-danger">email is required.</div>').fadeIn()
            showAlert('email is required.', 'danger')
            valid = false
        }
        if (!/^\S+@\S+\.\S+$/.test(email)) {
            showAlert('email is invalid.', 'danger')
            valid = false
        }
        if (!message || typeof message == 'undefined') {
            showAlert('message is required.', 'danger')
            valid = false
        }

        if (valid) {
            let msg = `<strong>Phone:</strong> ${phone}<br/><br/>${message}`

            let data = new FormData()
            data.append('to', 'natalie@dabsolute.com')
            // data.append('to', 'leowebguy@gmail.com')
            data.append('name', name || '')
            data.append('from', email)
            data.append('subj', 'Mademoiselle Form')
            data.append('msg', msg)

            $.post({
                url: 'https://api.gaunte.com/sendmail/',
                contentType: false,
                timeout: 0,
                processData: false,
                mimeType: 'multipart/form-data',
                data: data,
                success: function(r) {
                    r = $.parseJSON(r)
                    if (r.result) {
                        //console.log(`1 ${r.result}`)
                        $('[name=contactform]').trigger('reset')
                        showAlert('message sent!', 'success')
                        console.log(`%c${r.message}`, 'color:green')
                    } else {
                        //console.log(`2 ${r.result}`)
                        $('[name=contactform]').trigger('reset')
                        showAlert(r.message, 'danger')
                        console.log(`%c${r.message}`, 'color:red')
                    }
                },
                error: function(r) {
                    r = $.parseJSON(r)
                    //console.log(`3 ${r}`)
                    showAlert(r.message, 'danger')
                    console.log(`%c${r.message}`, 'color:red')
                }
            })
        }
    })

    $('form[name=contactform] input, form[name=contactform] textarea').keyup(function() {
        $('#block-answer').fadeOut()
    })

    let showAlert = (msg, type) => {
        $('#block-answer').html(`<div class="color-${type}">${msg}</div>`).fadeIn()
    }
})


